import React from "react";
import { FC, useEffect } from "react";
import moment from 'moment';

  
interface LastUpdateProps {
    lastUpdate: Date;
}
  
const LastUpdate: FC<LastUpdateProps> = props => {
  
    const {lastUpdate} = props;
    const [now, setNow] = React.useState(Date.now());
  
    useEffect(() => {
        const timer = setInterval(() => {
            setNow(Date.now());
        }, 5000);
        
        // clearing interval
        return () => clearInterval(timer);
      });
  
    moment.relativeTimeThreshold('ss',10);
  
    return (<React.Fragment>{moment(lastUpdate).from(now)}</React.Fragment>)
}

export default LastUpdate;