import { LogLevel } from '@microsoft/signalr';
import config from '../config';
import { authHeader } from '../helpers/authHeader';
import { ApplicationInstance } from '../types/application-instance';

export const applicationService = {
    loadAll: loadApplications,
    getVersion,
};

function loadApplications() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}/applications`, requestOptions).then(handleResponse);
}

function getVersion(app: ApplicationInstance) {
    const requestOptions = {
        method: 'GET'
    };

    if(app.versionUrl && app.versionUrl.indexOf('http') === -1) {
        app.versionUrl = 'https://' + app.versionUrl;
    }

    return fetch(`${app.versionUrl}`, requestOptions).then(handleResponse);
}

function handleResponse(response: any) {
    return response.text().then((text: string) => {            
        if (!response.ok) {
            var error = '';
            try{
                const data = text && JSON.parse(text);
                error = (data && data.message) || response.statusText;
            }catch(ex) {
                error = text || response.statusText;
            }
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                console.log(LogLevel.Warning, "Unauthorized request");
                window.location.reload();
            }
            return Promise.reject(error);
        } 
        else 
        {
            try
            {
                const data = text && JSON.parse(text);
                return data;
            }
            catch(ex) 
            {
                return text;
            }
        }
    });
}