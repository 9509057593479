import React, { FC } from "react";
import { EAppStatus } from '../../../types/app-status';
import { makeStyles, useTheme} from '@material-ui/core';

import CheckCirleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';

const useStatusStyles = makeStyles({
    red: {
      color: 'red'
    },
    orange: {
      color: 'orange'
    },
    green: {
      color: 'green'
    } 
});
  
interface StatusIconProps {
    status: EAppStatus;
};
  
const StatusIcon: FC<StatusIconProps> = props => {

    const theme = useTheme();
    const classes = useStatusStyles(theme);

    const { status } = props;


    return ( 
        status === EAppStatus.Online ? <CheckCirleIcon className={classes.green}></CheckCirleIcon> : 
        (status === EAppStatus.Alert ? <WarningIcon className={classes.orange}></WarningIcon> : <ErrorIcon className={classes.red}></ErrorIcon> )
    );
};

export default StatusIcon;