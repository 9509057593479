import React, { useEffect, FC, useContext } from "react";
import { makeStyles,Box, IconButton, Collapse, Table, TableCell, TableRow, TableBody, Typography, Link} from '@material-ui/core';


import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import red from '@material-ui/core/colors/red';

import LogsContext from '../../../contexts/logs-context';
import ApplicationContext from '../../../contexts/applications-context';
import { ApplicationInstance } from '../../../types/application-instance';
import { Log } from '../../../types/log';
import LogRow from './LogRow';
import LastUpdate from "./LastUpdate";
import StatusIcon from "./StatusIcon";





const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
    outdated: {
      color: red[900],
      fontWeight: 600
    },
    current: {
      color: 'black',
      fontWeight: 600
    }
});

interface RowProps {
    index: number,
    instance: ApplicationInstance,    
    lastVersion: string,
    lastUpdate: Date,
    onRefresh()
};

  
const InstanceRow: FC<RowProps> = props => {
  
    const logsContext = useContext(LogsContext);
    const appsContext = useContext(ApplicationContext);
    const groupByApplication = appsContext.groupBy === 'Application';
  
    const { instance, lastUpdate } = props;
    const [open, setOpen] = React.useState(false);    
    const classes = useRowStyles();  
  
    useEffect(() => {
      appsContext.checkAppVersion(instance);           
    },[]);
  
    return (
      <React.Fragment>
        <TableRow className={classes.root}>
          <TableCell width="40">
          {logsContext.logs.findIndex(c => c.source === instance.logServiceName) !== -1 && 
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open) }>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          }
          </TableCell>
          <TableCell component="th" scope="row" width="70px">
            <StatusIcon status={instance.status}></StatusIcon>           
          </TableCell>
          <TableCell>{groupByApplication ? instance.environmentAlias : instance.name}</TableCell>
          <TableCell><Link target="_blank" href={'http://'+ instance.url} rel="noreferrer" >{instance.url}</Link></TableCell>
          <TableCell className={props.lastVersion.localeCompare(instance.version, undefined, { numeric: true, sensitivity: 'base' }) === 1 ? classes.outdated : classes.current}>{instance.version}</TableCell>          
          <TableCell align="right"><LastUpdate lastUpdate={lastUpdate} /></TableCell>
        </TableRow>
        {logsContext.logs.findIndex(c => c.source === instance.logServiceName) !== -1 && (<TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  Log History
                </Typography>
                <Table size="small" aria-label="logs">
                  <TableBody>
                    {logsContext.logs.find(c => c.source === instance.logServiceName) 
                                     .logs.map((log: Log, index) => ( 
                        <LogRow index={index} log={log} instance={instance}/>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>)}
      </React.Fragment>
    );
  }
  
  export default InstanceRow