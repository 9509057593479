import React, { useContext, useEffect } from 'react';
import { Grid, } from '@material-ui/core';
import DrawerNavigation from './components/DrawerNavigation';
import { Route, Router, Switch, useParams, useRouteMatch } from 'react-router-dom';
import ApplicationDetails from './components/ApplicationDetails';

import ApplicationsContext from '../../contexts/applications-context';
import { history } from '../../helpers/history';
import { GroupParam } from '../../types/group-param';

const NewLayout = () => {

    let context = useContext(ApplicationsContext);

    let params = useParams<GroupParam>();
    let match = useRouteMatch();


    useEffect(() => {
        context.loadAll();        
    },[]);

    useEffect(() => {
        context.setGroupBy(params.groupBy && params.groupBy.toLowerCase() === 'env' ? 'Environment': 'Application');
    },[params.groupBy]);
            
    const handleRefresh = () => {
        context.loadAll();
    }   

    // eslint-disable-next-line
    return (
        <Router history={history}>
            <Switch>
                <Route path={`${match.path}/:code?`}>
                    <DrawerNavigation refreshAction={handleRefresh}>
                        <Grid container spacing={3}>
                            {/* <Grid item xs={6}>
                                <ErrorsChart></ErrorsChart>
                            </Grid>
                            <Grid item xs={6}>
                            <ErrorsChart></ErrorsChart>
                            </Grid> */}
                            <Grid item xs={12}>
                                <ApplicationDetails  />
                            </Grid>
                        </Grid>
                    </DrawerNavigation>
                </Route>                
            </Switch>            
        </Router>
    );
}

export default NewLayout;