import React from "react";
import { Component } from "react";
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

export default class Copyright extends Component {
    render() {
        return (
          <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://www.mstrack.com/">
              BIS - Jonatas
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
          </Typography>
        );
      }
}

