import { LogLevel } from '@microsoft/signalr';
import config from '../config';
import { authHeader } from '../helpers/authHeader';

export const logService = {
    getLatest,
    getLatestAppErrors: getLatestApplicationErrors,
    getLatestEnvErrors: getLatestEnvironmentErrors,
};

function getLatest() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}/logs`, requestOptions).then(handleResponse);
}

function getLatestApplicationErrors(code: string) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}/logs/${code}`, requestOptions).then(handleResponse);
}

function getLatestEnvironmentErrors(environment: string) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}/logs/environment/${environment}`, requestOptions).then(handleResponse);
}


function handleResponse(response: any) {
    return response.text().then((text: string) => {            
        if (!response.ok) {
            var error = '';
            try{
                const data = text && JSON.parse(text);
                error = (data && data.message) || response.statusText;
            }catch(ex) {
                error = text || response.statusText;
            }
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                console.log(LogLevel.Warning, "Unauthorized request");
                window.location.reload();
            }
            return Promise.reject(error);
        } 
        else 
        {
            try
            {
                const data = text && JSON.parse(text);
                return data;
            }
            catch(ex) 
            {
                return text;
            }
        }
    });
}