import { Component } from "react";
import React from 'react'
import config from '../config';

import { logService } from '../services/log'

import LogsContext from "../contexts/logs-context";
import AlertsContext from "../contexts/alerts-context";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { Log } from "../types/log";

export class LogState extends Component {

    static contextType = AlertsContext;

    ENDPOINT = config.apiUrl.replace('api', '');

    constructor(props) {
        super(props);       


        const connection = new HubConnectionBuilder()
            .withUrl(this.ENDPOINT + '/hubs/logs')
            .withAutomaticReconnect()
            .build();

        connection.start()
        .then(result => {
            console.log('Connected!');

            connection.on('LogsUpdate', (logs) => { 
                console.log("SignalR logs update");
                this.updateLogs(logs);
            });            
        })
        .catch(e => console.log('Connection failed: ', e));
    }

    
    state = {
        loading: false,        
        logs: [],
        item: null,
        error: null
    }

    selectItem = (vehicle) => {
        if(this.state.item !== vehicle) {
            this.setState({item: vehicle});
        }
    };
    
    updateLogs = (logs: Log[]) => {
        console.log("Updating logs");
        let currentLogs = this.state.logs;

        for(let l of logs) 
        {
            var source = currentLogs.find(s => s.source === l.source);

            if(source === undefined) {
                currentLogs.push({
                    source: l.source,
                    logs: [l]
                });
            } 
            else if(source.logs.length > 500)  
            {
                source.logs.shift();                        
                source.logs.push(l);
            }

        }

        if(source) {
            source.logs.sort((a: Log,b: Log) => a.transDate.getTime() - b.transDate.getTime());
        }
        
        this.setState({ logs: currentLogs, loading: false});
    };

    getSourcesLogs = (sources : string[]) => {
        
        var logs = this.state.logs.filter(c => sources.indexOf(c.source) !== -1);

        return logs.flatMap(s => s.logs);
    };

    getApplicationLogs = (code: string) => {
        this.setState({ loading: true});

        logService.getLatestAppErrors(code)
            .then(
                (logs: Log[]) => {
                    this.updateLogs(logs)
                },                
                (error: any) => { 
                    this.setState({ error: error });
                    this.context.error(error);
                }
            );
    };

    getEnvironmentLogs = (code: string) => {
        this.setState({ loading: true});

        logService.getLatestEnvErrors(code)
            .then(
                (logs: Log[]) => {
                    this.updateLogs(logs)
                },                
                (error: any) => { 
                    this.setState({ error: error });
                    this.context.error(typeof(error) === 'string' ? error : error.message);
                }
            );
    };
    
    render() {
        return <LogsContext.Provider value={{
            logs: this.state.logs,
            item: this.state.item,
            loading: this.state.loading,            
            error: this.state.error,
            selectItem: this.selectItem,
            getApplicationLogs: this.getApplicationLogs,
            getEnvironmentLogs: this.getEnvironmentLogs,
            getSourcesLogs: this.getSourcesLogs,
        }}>{this.props.children}</LogsContext.Provider>
    }
}