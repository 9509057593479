import React, { SetStateAction, useContext } from "react";
import { FC } from "react";

import FilterNoneIcon from '@material-ui/icons/FilterNone';
import { Button, Menu, MenuItem } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar'
import { Dispatch } from "react";
import ApplicationContext from '../../../contexts/applications-context'
import { history } from '../../../helpers/history';

const GroupByMenu = props => {

    const context = useContext(ApplicationContext);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleOpenGroupBy = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleGroupBy  = (group) => {
        history.push(group === 'Application' ? '/app' : '/env');
        setAnchorEl(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Toolbar>
        <div>
            <Button aria-controls="simple-menu" aria-haspopup="true" startIcon={<FilterNoneIcon/>} onClick={handleOpenGroupBy}>
            Group by: {context.groupBy}
            </Button>
            <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            >
            <MenuItem onClick={() => handleGroupBy('Application')}>Application</MenuItem>
            <MenuItem onClick={() => handleGroupBy('Environment')}>Environment</MenuItem>                
            </Menu>
        </div>
        </Toolbar>
    );
}

export default GroupByMenu;