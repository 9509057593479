import React, { useState, FC, useContext } from 'react';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import LoopIcon from '@material-ui/icons/Loop';
import ListItemText from '@material-ui/core/ListItemText';
import DnsIcon from '@material-ui/icons/Dns';

import MenuIcon from '@material-ui/icons/Menu';
import WebIcon from '@material-ui/icons/Web';
import RefreshIcon from '@material-ui/icons/Refresh';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme, ButtonGroup, Button } from '@material-ui/core';
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from '@material-ui/lab';
import Copyright from '../../../components/Copyright';
import GroupByMenu from './GroupByMenu';

import DrawerAction from '../../../types/common/drawer-action'
import ApplicationsContext from '../../../contexts/applications-context';
import { CodeParam } from '../../../types/code-param';

import {history} from '../../../helpers/history';
import { useParams } from 'react-router-dom';
import logo from '../../../logo.svg';
const drawerWidth = 300;

const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },           
    },
    logo:{
      marginRight: 10,
    },
    title: {
        flexGrow: 1,
        textAlign: 'left',
    },
    section: {
      ...theme.typography.button,          
      fontWeight: 600,
      color: 'gray',
      flexGrow: 1,
      textAlign: 'left',
      margin: 0,
      fontSize: '0.7rem'
    },
    topRow: {      
      display: 'flex',
      flexFlow: 'row wrap',
      flexGrow: 1
    },
    sectionDesc: {
      display: 'inline',
      flexGrow: 1,
      textAlign: 'left',
      marginTop: 0,
      fontWeight: 400,
      fontSize: '1.3rem'
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    contentFullscreen: {      
      marginTop: 0,
      height: 'calc(100vh - 70px)',
      width: '100%'
    },
    speedDial: {
      position: 'absolute',
      '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
        bottom: theme.spacing(2),
        right: theme.spacing(2),
      },
      '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
        top: theme.spacing(2),
        left: theme.spacing(2),
      },
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
      bottom: theme.spacing(2),
      right: theme.spacing(2)
    },
    actionsButtonGroup: {      
      textAlign: 'right', 
      marginTop: 0,
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    listRoot: {
      width: '100%',
      maxWidth: drawerWidth,
      backgroundColor: theme.palette.background.paper
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    nested2: {
      paddingLeft: theme.spacing(8),
    }    
}));



interface ResponsiveDrawerProps {
  container?: Element;
  title?: string;
  section?: string;
  children?: any;
  actions?: DrawerAction[];
  noMargin?: boolean
  drawerContent?: any;
  refreshAction?: any;
}

const DrawerNavigation : FC<ResponsiveDrawerProps> = props => {

  let context = useContext(ApplicationsContext);

  const theme = useTheme();
  const classes = useStyles(theme);

  let params = useParams<CodeParam>();
  let selectedCode = context.groupBy === 'Application' ? 
    (context.selectedApplication ? context.selectedApplication.code : null): 
    (context.selectedEnvironment ? context.selectedEnvironment.code : null);

  let code = params.code != null ? params.code : selectedCode;
  
  const [state, setState] = useState({
    mobileOpen: false,
    profileOpen: false,
  });

  
  const [open, setOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setState({ ...state, mobileOpen: !state.mobileOpen});
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSelectItem = (item) => () => {
    if(context.groupBy === 'Application') 
      history.push(`/app/${item.code}`)
    else
      history.push(`/env/${item.code}`)
  };


  const drawer = () => {
    
    return context.loading ? 
    (
      <div>
        <List>
            <ListItem>
              <ListItemIcon>
                <LoopIcon></LoopIcon>
              </ListItemIcon>              
              <ListItemText primary="Loading..." />
            </ListItem>
        </List>
      </div>
    )
    :     
    (
      <div>
        <List component="nav" className={classes.listRoot}>
          {(context.groupBy === 'Application' ? context.applications : context.environments).map((item) => (
              <ListItem button onClick={handleSelectItem(item)} key={item.code} selected={item.code.toLowerCase() === code.toLowerCase()} >
                <ListItemIcon>
                  {context.groupBy === 'Application' ? <WebIcon/>: <DnsIcon/> }
                </ListItemIcon>              
                <ListItemText primary={item.name} />      
              </ListItem>              
          ))}
        </List>
      </div>
    );
  };
  
  
  const { container } = props;    
  const { mobileOpen } = state;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>          
          <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle} className={classes.menuButton}>
            <MenuIcon />
          </IconButton>          
          <Typography variant="h6" className={classes.title}>
            Versions Monitor
          </Typography>
          <IconButton color="inherit" aria-label="refresh applications" edge="end" onClick={props.refreshAction} >
            <RefreshIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer 
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{ paper: classes.drawerPaper, }}
            ModalProps={{ 
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <GroupByMenu/>
            <div>
              {/* <UserProfileBar isOpen={state.profileOpen} handleOpenUserProfile={handleOpenUserProfile} handleUserProfileClose={handleUserProfileClose} /> */}
              <Divider />
              {props.drawerContent ? props.drawerContent : drawer()}
            </div>
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer classes={{ paper: classes.drawerPaper, }} variant="permanent" open>
            <GroupByMenu/>
            <div>
              {/* <UserProfileBar isOpen={state.profileOpen} handleOpenUserProfile={handleOpenUserProfile} handleUserProfileClose={handleUserProfileClose} /> */}
              <Divider />
              {props.drawerContent ? props.drawerContent : drawer()}
            </div>
          </Drawer>
        </Hidden>
      </nav>
      <main className={props.noMargin ? classes.contentFullscreen : classes.content}>
        <div className={classes.toolbar} />        
        <h3 className={classes.section}>{props.section}</h3>  
        <div className={classes.topRow}>
          { props.title && <h1 className={classes.sectionDesc}>{props.title}</h1> }              
           
          { props.actions != null && 
            <ButtonGroup variant="contained" color="primary" aria-label="outlined primary button group" className={classes.actionsButtonGroup}>
            { props.actions.map((action, index: number) => 
                <Button key={index} startIcon={action.icon} onClick={action.clickHandler}>{action.name}</Button>
            )}
            </ButtonGroup>
          }                 
        </div>
        { props.children }
        
        { props.actions != null && props.actions.length > 1 &&
          <SpeedDial
            ariaLabel="SpeedDial example"
            className={classes.speedDial}
            icon={<SpeedDialIcon />}
            onClose={handleClose}
            onOpen={handleOpen}
            open={open}
            direction="up"
          >
          { props.actions.map((action, index: number) => 
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={action.clickHandler}
            />        
          )}
          </SpeedDial>
        }
        { props.noMargin === false  && <Copyright />} 
      </main>
    </div>
  );  
}


export default DrawerNavigation