import React, { FC } from "react";
import { IconButton,  TableCell, TableRow, Chip, DialogTitle, DialogContent, DialogContentText, DialogActions, Dialog, Button, makeStyles, useTheme} from '@material-ui/core';
import Moment from 'react-moment';

import PageviewIcon from '@material-ui/icons/Pageview';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';

import { ELogType } from '../../../types/log-type';
import { Log } from '../../../types/log';
import { ApplicationInstance } from "../../../types/application-instance";

const useStyles = makeStyles({
  chip: {
    marginLeft: '20px'
  } 
});


interface LogRowProps {
    index: number,
    log: Log,
    instance: ApplicationInstance
};

  
const LogRow: FC<LogRowProps> = props => {
  
    const { index, log, instance } = props;
    const [open, setOpen] = React.useState(false);    

    const theme = useTheme();
    const classes = useStyles(theme);


    const fullWidth = true;
    const maxWidth = 'md';

    const handleOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    const getLogStatus = (log: Log) => {
      return log.type === ELogType.Debug || log.type === ELogType.Info ? (<Chip size="small" className={classes.chip} icon={<InfoIcon />} label="Debug" color="primary" />) :
             log.type === ELogType.Warning ? (<Chip size="small" className={classes.chip} icon={<WarningIcon />} label="Warning" />) :
                                             (<Chip size="small" className={classes.chip} icon={<ErrorIcon />}   label="Error" color="secondary" />);
    };
  
    return (
      <TableRow key={index}>
        <TableCell component="th" scope="row" width="180px">
          <Moment format="D/MM/yyyy H:mm:ss">{log.transDate}</Moment>
        </TableCell>
        <TableCell  width="80px">
          {getLogStatus(log)}
        </TableCell>
        <TableCell>{log.packetData.substring(0,512) + (log.packetData.length > 512 ? '...' : '')}</TableCell>        
        <TableCell align="right">
          <IconButton onClick={handleOpen}><PageviewIcon/></IconButton>    
          <Dialog open={open} 
                  onClose={handleClose} 
                  maxWidth={maxWidth}
                  fullWidth={fullWidth}
                  aria-labelledby="alert-dialog-title" 
                  aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{instance.name} ({instance.environmentAlias}) - <Moment format="D/MM/yyyy - H:mm:ss">{log.transDate}</Moment>{getLogStatus(log)}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <div>{log.packetData}</div>
                <div>{log.comments}</div>
              </DialogContentText>
            </DialogContent>
            <DialogActions>              
              <Button onClick={handleClose} color="primary" autoFocus>
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </TableCell>
      </TableRow>
    );
  }
  
  export default LogRow