import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router';

import { TableContainer, Table, TableCell, TableRow, TableHead, TableBody, Paper, IconButton} from '@material-ui/core';


import LogsContext from "../../../contexts/logs-context";
import ApplicationContext from '../../../contexts/applications-context';
import { Application } from '../../../types/application';

import { ApplicationInstance } from '../../../types/application-instance';
import { CodeParam } from '../../../types/code-param';
import { Environment } from '../../../types/environment';

import RefreshIcon  from '@material-ui/icons/RefreshOutlined';

import InstanceRow from './InstanceRow';


const ApplicationDetails = props => {

    const [lastUpdate, setLastUpdate ] = React.useState(new Date(Date.now()));
  
    let context = useContext(ApplicationContext);    
    let logsContext = useContext(LogsContext);    
    
    let params = useParams<CodeParam>();
    let selectedCode = context.groupBy === 'Application' ? 
                            (context.selectedApplication ? context.selectedApplication.code : null): 
                            (context.selectedEnvironment ? context.selectedEnvironment.code : null);
                            
    let code = params.code != null ? params.code : selectedCode;

    useEffect(() => {
      if(code != null) {
        if(context.groupBy === 'Application') {
          logsContext.getApplicationLogs(code);
        } else {
          logsContext.getEnvironmentLogs(code);
        }
      }
    }, [code]);

    useEffect(() => {
      const timer = setInterval(() => {
        setLastUpdate(new Date(Date.now()));
      }, 240000);
      
      // clearing interval
      return () => {
        clearInterval(timer);
      }
    },[]);

    const handleRefresh = () => {
      setLastUpdate(new Date(Date.now()));
    };

    return (
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Status</TableCell>
            <TableCell>{context.groupBy === 'Application' ? 'Environment' : 'Application'}</TableCell>
            <TableCell>Url</TableCell>
            <TableCell>Version</TableCell>            
            <TableCell align="right"><IconButton onClick={handleRefresh}><RefreshIcon/></IconButton></TableCell>
          </TableRow>
        </TableHead>
        {context.groupBy === 'Application' ? 
        (<TableBody>
          {!context.loading && code && context.applications.find((i: Application) => i.code.toLowerCase() === code.toLowerCase()) && context.applications.find((i: Application) => i.code === code).instances.map((row: ApplicationInstance, index) => (
            <InstanceRow key={row.id} index={index} instance={row} onRefresh={handleRefresh} lastUpdate={lastUpdate}
              lastVersion={context.applications.find((i: Application) => i.code === code).instances.map(c => c.version).filter(c=> c != null).reduce((acc,cur) => acc.localeCompare(cur, undefined, { numeric: true, sensitivity: 'base' }) === 1 ? acc : cur, '')}/>
          ))}
        </TableBody>) :
        (<TableBody>
          {!context.loading && code && context.environments.find((i: Environment) => i.code.toLowerCase() === code.toLowerCase()) && context.environments.find((i: Environment) => i.code === code).instances.map((row: ApplicationInstance, index) => (
            <InstanceRow key={row.id} index={index} instance={row} onRefresh={handleRefresh} lastUpdate={lastUpdate}
              lastVersion={context.environments.find((i: Environment) => i.code === code).instances.map(c => c.version).filter(c=> c != null).reduce((acc,cur) => acc.localeCompare(cur, undefined, { numeric: true, sensitivity: 'base' }) === 1 ? acc : cur, '')} />
          ))}
        </TableBody>)        
        }
      </Table>
      </TableContainer>
    );
}

export default ApplicationDetails;