import React from 'react';
import { Application } from '../types/application';
import { ApplicationInstance } from '../types/application-instance';
import { Environment } from '../types/environment';

export default React.createContext({    
    loading: false,        
    groupBy: null,    
    environments: [],
    applications: [],
    selectedEnvironment:null,
    selectedApplication: null,    
    error: null,
    setGroupBy: (group: string) => {},
    selectEnvironment: (env: Environment) => {},        
    selectApplication: (app: Application) => {},        
    checkAppVersion: (env: ApplicationInstance) => {},
    loadAll: () => {},    
});