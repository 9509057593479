import { Component } from "react";
import React from 'react'

import { applicationService } from "../services/application"

import AlertsContext from "../contexts/alerts-context";
import ApplicationContext from "../contexts/applications-context";
import { Application } from "../types/application";
import { EAppStatus } from "../types/app-status";
import { ApplicationInstance } from "../types/application-instance";
import { Environment } from "../types/environment";


export class ApplicationState extends Component {

    static contextType = AlertsContext;
    

    state = {
        loading: false,
        environments: [],
        applications: [],
        groupBy: 'Application',
        selectedEnvironment: null,
        selectedApplication: null,
        error: null
    }

    selectEnvironment = (env) => {
        if(this.state.selectedEnvironment !== env) {
            this.setState({selectedEnvironment: env});
        }
    };
    
    selectApplication = (app) => {
        if(this.state.selectedApplication !== app) {
            this.setState({selectedApplication: app});
        }
    };
    
    setGroupBy = (group) => {
        this.setState({groupBy: group});
    }

    checkAppVersion = (instance) => {
        instance.version = 'updating';
        instance.status = EAppStatus.Alert;        

        this.setState({});

        applicationService.getVersion(instance)
            .then(
                (version) => {
                    
                    if(typeof(version) == 'string' && version.indexOf("<html>") !== -1) {
                        const regex = /MST Reports Web Server is running \(Build: (.+)\)/m;

                        var matches = version.match(regex);
                            
                        if(matches && matches.length > 1) {
                            instance.version = matches[1];
                        }
                    } else {
                        instance.version = version.version;
                    }
                    
                    instance.status = instance.version ? EAppStatus.Online : EAppStatus.Offline;
                    this.setState({});
            }
        ).catch((err) => {
            instance.version = ''
            instance.status = EAppStatus.Offline;

            this.setState({});
        });
    };
    

    loadAll = () => {
        this.setState({ loading: true});

        applicationService.loadAll()
            .then(
                (instances: ApplicationInstance[]) => {

                    var applications = [];
                    var environments = [];

                    instances.forEach((instance: ApplicationInstance) => {
                        
                        // Create Applications Array
                        var appIndex = applications.findIndex((c: Application) => c.code.toLowerCase() === instance.code.toLowerCase());
            
                        if(appIndex === -1) {
                            applications.push({
                                code: instance.code,
                                name: instance.name,
                                instances: [instance]
                            });
                        } else {
                            if(applications[appIndex].instances.findIndex((c: ApplicationInstance) => c.name === instance.name && c.environmentAlias === instance.environmentAlias) === -1)
                                applications[appIndex].instances.push(instance);
                        }
                        
                        // Create Environment Array

                        var envIndex = environments.findIndex((c: Environment) => c.code.toLowerCase() === instance.environmentAlias.toLowerCase());
            
                        if(envIndex === -1) {
                            environments.push({
                                code: instance.environmentAlias,
                                name: instance.environmentAlias,
                                instances: [instance]
                            });
                        } else {
                            if(environments[envIndex].instances.findIndex((c: ApplicationInstance) => c.name === instance.name && c.code === instance.code) === -1)
                                environments[envIndex].instances.push(instance);
                        }
                    });

                    applications.sort((a,b) => a.name > b.name ? 1 : a.name === b.name ? 0 : -1);

                    this.setState({
                        environments: environments, 
                        applications: applications, 
                        selectedApplication: applications[0], 
                        selectedEnvironment: environments[0], 
                        loading: false
                    });
                },
                (error: any) => { 
                    this.setState({ error: error });
                    this.context.error(typeof(error) === 'string' ? error : error.message);
                }
            );
    };

    render() {
        return <ApplicationContext.Provider value={{
            groupBy: this.state.groupBy,
            setGroupBy: this.setGroupBy,
            environments: this.state.environments,
            applications: this.state.applications,
            selectedEnvironment: this.state.selectedEnvironment,
            selectedApplication: this.state.selectedApplication,
            loading: this.state.loading,            
            error: this.state.error,
            selectEnvironment: this.selectEnvironment,
            selectApplication: this.selectApplication,
            loadAll: this.loadAll,
            checkAppVersion: this.checkAppVersion,
        }}>{this.props.children}</ApplicationContext.Provider>
    }
}