import React from 'react';
import { render } from 'react-dom';
import GlobalState from './context-components/GlobalState';
import { SnackbarProvider } from 'notistack';

import './index.css';
import App  from './App';
import * as serviceWorker from './serviceWorker';

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: blue[500],
    },
    secondary: {
      main: '#ff1744',
    },
  },
});

render(
    <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3}>
            <GlobalState><App /></GlobalState>
        </SnackbarProvider>
    </ThemeProvider>, document.getElementById('root')    
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
