import React from 'react';
import { Log } from '../types/log';

export default React.createContext({    
    loading: false,
    logs: [],
    item: null,
    error: null,
    selectItem: (log: Log) => {},
    getApplicationLogs: (code: string) => {},
    getEnvironmentLogs: (code: string) => {},
    getSourcesLogs: (sources: string[]) => { return [] as Log[]},
});