import React, { Component } from 'react'

import AlertsContext from '../contexts/alerts-context'

import { withSnackbar } from 'notistack';

interface Props {
    enqueueSnackbar: any;
    closeSnackbar: any;
    children?: any;
  }

class GlobalState extends Component<Props> {

    state = {
        alerts: [],
        error: null,
        loading: false,
    }

    alert = (message, type) => {
        var alert = { 
            message: message, 
            type: type
        }

        const updatedAlerts = [...this.state.alerts];
        const updatedAlertIndex = updatedAlerts.findIndex(
          item => item.type === type && item.message === message
        );
    
        if (updatedAlertIndex < 0) {
            updatedAlerts.push(alert);
        } 

        this.setState({alerts: updatedAlerts});

        this.props.enqueueSnackbar(message, { variant: type });
    };

    clearAlerts = () => {
        this.setState({alerts: []});
    };        
    
    success = (message: string) => this.alert(message, 'success');
    error = (message: string) => this.alert(message, 'error');
    warning = (message: string) => this.alert(message, 'warning');
    info = (message: string) => this.alert(message, 'info');
    
    
    render() {           

        return <AlertsContext.Provider value={{
                alerts: this.state.alerts,
                clearAlerts: this.clearAlerts,
                success: this.success,
                info: this.info,
                warning: this.warning,
                error: this.error
            }}>{this.props.children}</AlertsContext.Provider>;
    };
}

export default withSnackbar(GlobalState);