import React, { Component } from 'react';
import './App.css';
import { Switch, Router, Route, Redirect } from 'react-router-dom';

import { history } from './helpers/history';
import newlayout from './sections/homepage/NewLayout';

import AlertsContext from './contexts/alerts-context';
import { ApplicationState } from './context-components/ApplicationState';
import { StateRoute } from './components/StateRoute';
import { LogState } from './context-components/LogState';

class App extends Component {

  static contextType = AlertsContext;

  constructor(props: any) {
      super(props);

      history.listen((location: any, action: any) => {
          // clear alert on location change
          this.context.clearAlerts();
      });
  }

  render() {
    return (
      <div className="App">   
        <LogState>
          <Router history={history}>
            <Switch>                            
              <Route exact path={"/"} >
                <Redirect to="/app" />
              </Route>              
              <StateRoute path={"/:groupBy"} component={newlayout} state={ApplicationState}  />              
            </Switch>
          </Router>
        </LogState>       
      </div>
    );
  }
}


export default App;
