import React from 'react';


export default React.createContext({    
    alerts: [],
    clearAlerts: () => {},
    success: (message: string) => {},
    error: (message: string) => {},
    warning: (message: string) => {},
    info: (message: string) => {}
});